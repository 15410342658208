@font-face {
  font-family: "ProximaNova";
  src:  url("../assets/fonts/ProximaNova-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "ProximaNova";
  src:  url("../assets/fonts/ProximaNova-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: bold;
}

@font-face {
  font-family: "ProximaNova";
  src:  url("../assets/fonts/ProximaNova-Thin.otf") format("truetype");
  font-weight: 100;
  font-style: thin;
}

@font-face {
  font-family: "ProximaNova";
  src:  url("../assets/fonts/ProximaNova-Extrabold.otf") format("truetype");
  font-weight: 900;
  font-style: bolder;
}
