$primary: #001e5e;
$secondary: #E93B55;
$font-primary: #222222;
$font-secondary: #4f4f4f;
$background-window: #FAFAFE;
$white: #fff;
$grey-secondary: #e0e0e0;
$grey-dark: #828282;
$card-border-color:#E9E9EF;
$theme-colors: (
  "primary":  #001e5e,
  "grey": #f1f1f1,
  "secondary": #39f,
  "white": #fff,
  "primary-font": #333,
  "secondary-font": #4f4f4f,
  "danger": #E93B55,
  "success": #2ac940,
);

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
  3xl: 1650px,
);
